<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" :title="type === 'edit' ? '编辑自定义部门' : '新增自定义部门'" width="1000px"
      :close-on-click-modal="false" :before-close="closeTab">
      <div style="padding: 0 20px;">
        <el-form ref="formData" :model="formData" :rules="rules" inline size="small" label-width="120px">
          <div class="clearfix">
            <el-form-item label="名称" prop="name" class="floatL">
              <el-input v-model="formData.name" :style="inputWidth" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="description" class="floatR">
              <el-input v-model="formData.description" :style="inputWidth" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="负责人公司" prop="company_relation" class="floatL">
              <el-cascader
                :options="companyList"
                :props="companyProps"
                :style="inputWidth"
                v-model="formData.company_relation"
                @change="handleChangeCompany">
              </el-cascader>
            </el-form-item>
            <el-form-item label="负责人" prop="leaderUserId" class="floatR">
              <el-select v-model="formData.leaderUserId" filterable :style="inputWidth" placeholder="请先选择公司">
                <el-option v-for="item in user_list" :key="item.userId" :label="item.name + '(' +item.username +')'" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关联公司" class="floatL">
              <el-cascader
                ref="companyMsg"
                :options="companyList"
                :props="companyProps"
                :style="inputWidth"
                v-model="company"
                @change="handleChangeDepart">
              </el-cascader>
            </el-form-item>
            <el-form-item label="关联部门" class="floatR">
              <el-cascader
                ref="partMsg"
                v-model="department"
                expandTrigger="hover"
                :options="departmentList"
                :props="departmentProps"
                :style="inputWidth"
                placeholder="请先选择关联公司"
                @change="createPart">
              </el-cascader>
            </el-form-item>
          </div>
          <div class="panel">
            <p class="tit">关联部门</p>
            <template>
              <el-tag type="success" v-for="(item, index) in formData.relationCompany" :key="item.udId"
                closable @close="removeTag(index)" style="margin: 0 5px 5px 0">
                {{ item.name }}
              </el-tag>
            </template>
          </div>
        </el-form>
        <div slot="footer" style="text-align: right; margin-top: 2em;">
          <el-button size="small" @click="closeTab">取消</el-button>
          <el-button size="small" type="primary" @click="submit">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { getCascaderData, deepClone } from '@/utils/index'
import { getCompanyUsers, addCustomDepart, editCustomDepart } from '@/api/customDepartment'
export default {
  data() {
    return {
      formData: {
        id: null,
        name: '',
        description: '',
        relationCompany: [],
        leaderUserId: null,
        company_relation: []
      },
      inputWidth: 'width: 320px',
      company: [],
      departmentList: [],
      department: [],
      user_list: [],
      companyName: '',
      companyProps: {
        label: 'ucName',
        value: 'ucId',
        children: 'childrenCompanyList'
      },
      departmentProps: {
        label: 'udName',
        value: 'udId',
        children: 'childrenDepartmentList'
      },
      rules: {
        name: [validFn.required()],
        leaderUserId: [validFn.requiredC()],
        company_relation: [validFn.requiredC()]
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    companyList: {
      type: Array,
      default: () => { return [] }
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: null
    },
    relationCompany: {
      type: Array,
      default: function() { return [] }
    },
    leaderUserId: {
      type: Number,
      default: null
    },
    leaderCompanyId: {
      type: Number,
      default: null
    },
    userList: {
      type: Array,
      default: function() { return [] }
    }
  },
  watch: {
    visible: function(val) {
      if (val) {
        console.log(this.companyList)
        // Object.keys(this.formData).forEach(key => {
        //   this.formData[key] = this[key]
        // })
        this.formData.id = this.id
        this.formData.name = this.name
        this.formData.description = this.description
        this.formData.relationCompany = this.relationCompany
        this.formData.leaderUserId = this.leaderUserId
        this.formData.company_relation = this.relationCompany
        this.user_list = deepClone(this.userList)
        if (this.type === 'edit') {
          // 逆向递归，组装负责人公司级联 ucId数组
          this.formData.company_relation = getCascaderData(this.companyList, this.leaderCompanyId, 'company')
        }
      }
    }
  },
  methods: {
    closeTab() {
      this.$refs.formData.resetFields()
      this.$emit('update:visible', false)
    },
    // 改变公司取 人员数据
    handleChangeCompany(val) {
      console.log(val)
      if (this.formData.leaderUserId) {
        this.formData.leaderUserId = null
        this.user_list = []
      }
      const params = {
        pageNum: 1,
        pageSize: 99999,
        ucId: val[val.length - 1]
      }
      getCompanyUsers(params).then(res => {
        if (res.code === '000000' && res.data.list) {
          this.user_list = res.data.list
        } else {
          this.user_list = []
          this.$message.error('获取人员信息失败！')
        }
      }).catch(() => {
        this.user_list = []
      })
    },
    // 改变关联公司，取部门数据
    handleChangeDepart(val) {
      const names = this.$refs.companyMsg.getCheckedNodes(true)
      if (names.length > 0) {
        console.log(names[0])
        this.companyName = names[0].label
      }
      this.getCascaderArr(val, this.companyList)
    },
    // 递归处理级联数据，根据公司ID查部门数据 val: 级联ID数组，data: 公司级联数据，type: 查询的字段
    getCascaderArr(val, data) {
      data.forEach(item => {
        if (item.ucId === val[0]) {
          if (val.length === 1) {
            this.departmentList = item.departmentList ? item.departmentList : []
          } else {
            const d = item.childrenCompanyList
            this.getCascaderArr(val.slice(1), d)
          }
        }
      })
    },
    // 生成关联数据
    createPart(type) {
      // 下拉收起时
      if (type) {
        const names = this.$refs.partMsg.getCheckedNodes()
        const json = {
          ucId: this.company[this.company.length - 1],
          udId: this.department[this.department.length - 1],
          name: this.companyName + ':' + names[0].label
        }
        const find = this.formData.relationCompany.filter(item => {
          return item.udId === json.udId
        })
        if (find.length === 0) {
          this.formData.relationCompany.push(json)
        }
        this.department = []
        console.log(this.formData.relationCompany)
      }
    },
    removeTag(index) {
      this.formData.relationCompany.splice(index, 1)
    },
    submit() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            name: this.formData.name,
            leaderUserId: this.formData.leaderUserId,
            description: this.formData.description,
            leaderCompanyId: this.formData.company_relation[this.formData.company_relation.length - 1]
          }
          const depart = []
          if (this.formData.relationCompany.length > 0) {
            this.formData.relationCompany.forEach(item => {
              depart.push({
                ucId: item.ucId,
                udId: item.udId
              })
            })
            params.deptList = depart
          }
          if (this.type === 'add') {
            addCustomDepart(params).then(res => {
              if (res.code === '000000') {
                this.$message.success(res.message)
                this.$refs.formData.resetFields()
                this.$emit('update:visible', false)
                this.$emit('success')
              }
            }).catch(err => {
              console.log(err)
            })
          } else if (this.type === 'edit') {
            params.id = this.formData.id
            editCustomDepart(params).then(res => {
              if (res.code === '000000') {
                this.$message.success(res.message)
                this.$refs.formData.resetFields()
                this.$emit('update:visible', false)
                this.$emit('success')
              }
            }).catch(err => {
              console.log(err)
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.panel {
  border: 1px solid #c9daf8;
  min-height: 100px;
  padding: 20px;
  width: calc(100% - 20px);
  margin: 10px auto;
  position: relative;
  .tit {
    position: absolute;
    top: -15px;
    left: 15px;
    line-height: 30px;
    padding: 0 10px;
    margin: 0;
    color: #606266;
    font-size: 14px;
    font-weight: bold;
    background: #ffffff;
  }
}
</style>

