<template>
  <div class="app-container">
    <el-form :model="searchForm" inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="部门名称" clearable></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button type="success" size="small" @click="addTab">新增</el-button>
    </el-form>
    <el-table v-loading="loading" :data="dataList" border size="small" height="calc(100% - 120px)" :header-cell-style="{background: '#41a1ff', color: '#ffffff'}">
      <el-table-column label="名称" prop="name" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column label="绑定部门">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.cdmList" :key="item.id" size="small" style="margin:0 5px 4px 0;">{{ item.ucName + '-' + item.udName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="负责人所属公司" prop="companyName" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column label="负责人" prop="userName" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column label="备注" prop="description" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="编辑" placement="top">
            <i class="table_icons el-icon-edit" @click="editNode(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="delNode(scope.row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 2em; text-align: center;">
      <el-pagination
        :page-size="pageSize"
        :page-sizes="[10, 20 ,50 ,100]"
        :current-page="page"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <!-- 新增/编辑 -->
    <add-panel v-bind.sync="formData" @success="search"></add-panel>
  </div>
</template>
<script>
import addPanel from './addPanel'
import { getCustomDepart, getCompanyAndDepartmen, getCompanyUsers, delCustomDepart } from '@/api/customDepartment'
export default {
  components: { addPanel },
  data() {
    return {
      searchForm: {
        name: ''
      },
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      companyList: [], // 公司架构数据
      formData: {
        visible: false,
        type: '',
        userList: [],
        companyList: [],
        name: '',
        description: '',
        id: null,
        relationCompany: [],
        leaderUserId: null,
        leaderCompanyId: null
      }
    }
  },
  mounted() {
    this.getData()
    this.getCompanyDepart()
  },
  methods: {
    getData() {
      const params = {
        name: this.searchForm.name,
        pageNum: this.page,
        pageSize: this.pageSize
      }
      this.loading = true
      this.dataList = []
      getCustomDepart(params).then(res => {
        if (res.code === '000000' && res.data.list) {
          this.dataList = res.data.list
          this.total = res.data.total
        } else {
          this.total = 0
        }
        this.loading = false
      }).catch(() => {
        this.total = 0
        this.loading = false
      })
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    },
    search() {
      if (this.page === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    editNode(item) {
      const depart = []
      if (item.cdmList.length) {
        item.cdmList.forEach(el => {
          depart.push({
            udId: el.udId,
            ucId: el.ucId,
            name: el.ucName + ':' + el.udLongName
          })
        })
      }
      this.formData.type = 'edit'
      this.formData.companyList = this.companyList
      this.formData.name = item.name
      this.formData.description = item.description
      this.formData.id = item.id
      this.formData.relationCompany = depart
      this.formData.leaderUserId = item.leaderUserId
      this.formData.leaderCompanyId = item.leaderCompanyId
      this.getUsers(item.leaderCompanyId)
    },
    delNode(id) {
      this.$confirm('确定删除该条数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCustomDepart(id).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.search()
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message.info('已取消！')
      })
    },
    addTab() {
      this.formData.companyList = this.companyList
      this.formData.visible = true
      this.formData.type = 'add'
    },
    getCompanyDepart() {
      getCompanyAndDepartmen().then(res => {
        if (res.code === '000000' && res.data) {
          this.companyList = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取公司下的人员，编辑时回填数据
    getUsers(id) {
      const params = {
        pageSize: 99999,
        pageNum: 1,
        ucId: id
      }
      this.loading = true
      getCompanyUsers(params).then(res => {
        if (res.code === '000000' && res.data.list) {
          res.data.list.forEach(item => {
            if (item.username !== '') {
              item.nameShow = item.name + '(' + item.username + ')'
            }
          })
          this.formData.userList = res.data.list
          this.formData.visible = true
          this.loading = false
        } else {
          this.$message.error('获取公司人员数据异常！')
          this.loading = false
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
