import request from '@/utils/request'

// 自定义部门
export function getCustomDepart(params) {
  return request({
    url: '/customDepartment/get/page',
    method: 'get',
    params: params
  })
}
// 新增
export function addCustomDepart(params) {
  return request({
    url: '/customDepartment/add',
    method: 'post',
    data: params
  })
}
// 修改
export function editCustomDepart(params) {
  return request({
    url: '/customDepartment/update',
    method: 'put',
    data: params
  })
}
//  删除
export function delCustomDepart(params) {
  return request({
    url: '/customDepartment/delete/' + params,
    method: 'delete'
  })
}
// 获取公司部门列表
export function getCompanyAndDepartmen() {
  return request({
    url: 'user/get/companyAndDepartment',
    method: 'get'
  })
}
// 获取公司下人员列表
export function getCompanyUsers(params) {
  return request({
    url: '/user/get/list',
    method: 'get',
    params: params
  })
}
